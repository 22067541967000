import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import BlockParagraph from '../components/blockParagraph';
import simpleBundlesLogo from '../images/logo-simplebundles.svg';
import backofficePlaybook from '../images/fpo-backoffice-playbook.png';

const EbookPage = () => {
  const pageTitle = 'Simple Bundles eBook Subscribed';
  const pageImage = simpleBundlesLogo;

  return (
    <Layout pageTitle={pageTitle} pageImage={pageImage} intro={false} headerAnimate={false} navbarAnimate={false}>
      <Seo title={pageTitle} />

      <Hero heroText="Every Brand Dreams of Growth—Your Backend Makes or Breaks It" extraClasses="padding-bottom-none" />

      <section className="block block-section padding-top-none">
        <div className="block g-m-1-13 g-l-4-12 padding-none">
          <div className="g-m-1-13 g-l-1-13">
            <div className="t-36">Thank you! Your eBook has been emailed to you. Please check your inbox (or spam folder) to download it.</div>
          </div>
        </div>
        <div className="block g-m-1-13 g-l-4-12 padding-none">
          <div className="g-m-1-13">
            <div className="margin-top-medium">
              <img className="img-width-full" src={backofficePlaybook} alt="Simple Bundles eBook" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EbookPage;
